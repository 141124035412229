import React from "react"
import PropTypes from "prop-types"

import { Container } from "react-bootstrap"

const ContainerWrapper = ({ children, data, fluid, classNames }) => {
  return (
    <Container fluid={fluid} className={classNames}>
      {children}
    </Container>
  )
}

ContainerWrapper.prototype = {
  fluid: PropTypes.bool,
  children: PropTypes.node,
  data: PropTypes.object,
  classNames: PropTypes.string,
}

ContainerWrapper.defaultProps = {
  fluid: false,
  children: null,
  data: {},
  classNames: "container",
}

export default ContainerWrapper
