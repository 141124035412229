import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"

const Section = ({ title, paras }) => (
  <Row
    className="tacSection page-section"
    style={{
      backgroundImage: `url(${"/terms-1366x360.png"})`,
      // backgroundAttachment: "fixed",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingTop: "5rem",
      paddingBottom: "5rem",
    }}
  >
    <Col sm={{ span: 10, offset: 1 }} className="paddingZero">
      <div className="text-center">
        <h2>{title || ""}</h2>
        {paras.length ? paras.forEach(para => <p>{para}</p>) : <></>}
      </div>
    </Col>
  </Row>
)

Section.propTypes = {
  title: PropTypes.string,
  paras: PropTypes.arrayOf(PropTypes.string),
}

Section.defaultProps = {
  title: "Terms and Conditions",
  paras: [],
}

export default Section
