import React from "react"
import { Container } from "react-bootstrap"

import SEO from "../components/seo"
import ContainerWrapper from "../components/container"
import TACSection from "../components/partials/tac"

const IndexPage = ({ children, location }) => {
  return (
    <>
      <SEO title="Terms and Conditions" keywords={[`mazrui`, `careers`]} />
      <ContainerWrapper classNames="home-sections" fluid>
        <TACSection />
      </ContainerWrapper>
      <ContainerWrapper classNames="main tac-page" fluid>
        <Container style={{ paddingTop: "1rem" }}>
          <h3>Legal Notices </h3>

          <p>Please read these terms carefully before using this site.</p>

          <p>
            This web site is provided by Mazrui International and may only be
            used for informational purposes only. By viewing the site or
            downloading materials from the site, you agree to abide by the terms
            and conditions set forth in this notice. If you do not agree to
            abide by these terms and conditions, do not use the site or download
            materials from the site.
          </p>

          <h3>Limited License </h3>

          <p>
            Subject to the terms and conditions set forth in this Agreement,
            Mazrui International grants you a non-exclusive, non-transferable,
            limited right to access, use and display this site and the materials
            thereon. You agree not to interrupt or attempt to interrupt the
            operation of the site in any way.
          </p>

          <h3>Information Use and Disclosure </h3>

          <p>
            Mazrui International uses the information that we collect on the
            Site to contact you when requested in order to further discuss your
            interest in our company, our services, and to send information
            regarding our company or partners, such as marketing promotions and
            events. This information may also be used to improve the services we
            provide you. The information is collected and stored in a manner
            that is appropriate for the nature of the data that we collect, and
            the need to fulfill your request. This information is not provided
            to third parties for their own use, unless specifically authorized
            by you.
          </p>

          <h3>Disclaimer and Limitation of Liability</h3>

          <p>
            Whilst every effort is made to ensure the accuracy of the content
            Mazrui International does not warrant the accuracy or completeness
            of the materials or the reliability of any advice, opinion,
            statement or other information displayed or distributed through the
            site. You acknowledge that any reliance on any such opinion, advice,
            statement, memorandum, or information shall be at your sole risk.
            Mazrui International reserves the right, in its sole discretion, to
            correct any errors or omissions in any portion of the site. Mazrui
            International may make any other changes to the site, the materials
            and the products, programs, services or prices (if any) described in
            the site at any time without notice.
          </p>

          <p>
            This site, the information and materials on the site, and the
            software made available on the site, are provided “as is” without
            any representation or warranty, express or implied, of any kind,
            including, but not limited to, warranties of merchantability,
            non-infringement, or fitness for any particular purpose, or
            non-infringement of any content published on or available throughout
            the site; this site available is free of viruses or other components
            that may infect, harm or cause damage to your computer equipment or
            any other property when you access, browse, download from or
            otherwise use this site.
          </p>

          <p>
            Under no circumstances, including but not limited to Mazrui
            International’ negligence, shall Mazrui International and/or any of
            its subsidiaries, affiliates and partners be liable for any of the
            following losses or damage (whether such losses where foreseen,
            foreseeable, known or otherwise): (a) loss of data; (b) loss of
            revenue or anticipated profits; (c) loss of business; (d) loss of
            opportunity; (e) loss of goodwill or injury to reputation; (f)
            losses suffered by third parties; or (g) any indirect,
            consequential, special or exemplary damages arising from the use of
            the Site regardless of the form of action.
          </p>

          <h3>No Warranty</h3>

          <p>
            The Site’s content, including the information, names, images,
            pictures, logos and icons regarding or relating to Mazrui
            International and/or any of its subsidiaries, affiliates and
            partners, its products and services (or to third party products and
            services), is provided “as is” and on an “is available” basis
            without any representations or any kind of warranty made (whether
            express or implied by law) to the extent permitted by law, including
            the implied warranties of satisfactory quality, fitness for a
            particular purpose, non-infringement, compatibility, security and
            accuracy. Mazrui International does not warrant that functions
            contained in the Site will be uninterrupted or error free, that
            defects will be corrected, or that the Site or the server that makes
            it available are free of viruses or bugs.
          </p>

          <p>
            For safety purposes, we advise that you never reveal any personal
            information about yourself or anyone else and please do not include
            postal addresses of any kind.
          </p>

          <h3>Intellectual Property, Copyright and Trademarks</h3>

          <p>
            The names, images and logos in all the screens displaying the pages
            and in the pages themselves in this Site, identifying Mazrui
            International or third parties and their products and services, are
            subject to copyright, design rights and trademarks of Mazrui
            International and/or any of its subsidiaries, affiliates and
            partners, and/or third parties. Nothing contained in these terms
            shall be construed as conferring by implication, estoppels or
            otherwise any license or right to use any trademark, patent, design
            right or copyright of Mazrui International and/or any of its
            subsidiaries, affiliates and partners or any other third party.
          </p>

          <p>
            All the information and material the screens and pages contain and
            in their arrangement, and all the trade and service marks contained
            on this Site are the exclusive property of Mazrui International.
          </p>

          <h3>Variation</h3>

          <p>
            Mazrui International may change these terms at any time by posting
            changes online. Please review these terms regularly to ensure you
            are aware of any update made by Mazrui International. Your continued
            use of the Site after changes are posted means you agree to be
            legally bound by these terms as updated and/or amended.
          </p>

          <h3>Governing Law and Jurisdiction</h3>

          <p>
            These Terms of Use shall be governed by and interpreted in
            compliance with the laws of the United Arab Emirates and you agree
            to submit to the exclusive jurisdiction of the courts of the United
            Arab Emirates.
          </p>
        </Container>
      </ContainerWrapper>
    </>
  )
}

export default IndexPage
